(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/targeting/assets/javascripts/targeting.js') >= 0) return;  svs.modules.push('/components/components/targeting/assets/javascripts/targeting.js');

var svs = svs || {};

(function(svs) {
  'use strict';

  svs.components = svs.components || {};

  var targeting = svs.components.targeting = svs.components.targeting || {};

  targeting.targets = targeting.data ? targeting.data.targets : {};

  targeting.getTarget = function(testName) {
    return targeting.targets[testName];
  };

  targeting.isTargeted = function(testName, targetName) {
    if (!testName || !targetName) {
      throw new Error('isTargeted requires test and target names');
    } else {
      return targeting.targets[testName] === targetName;
    }
  };
})(svs);


 })(window);