(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/targeting/helpers/targeting.js') >= 0) return;  svs.modules.push('/components/components/targeting/helpers/targeting.js');

(function() {
  'use strict';

  var serverSide = typeof exports === 'object';
  if (!serverSide) {
    registerHelpers(Handlebars);
  } else {
    registerHelpers(require('hbs'));
  }

  function isTargeted(value) {
    var model; var target; var targetName; var testName;

    if (!serverSide) {
      model = svs.components.targeting.data;
    } else {
      model = value.data.root;
    }

    testName = value.hash.test;
    targetName = value.hash.target;
    if (!testName || !targetName) {
      throw new Error('Targeting helper requires test and target names');
    } else {
      target = model.targets ? model.targets[testName] : undefined;
      if (target === targetName) {
        return value.fn(this);
      }
      return value.inverse(this);
    }
  }

  function registerHelpers(hbs) {
    hbs.registerHelper('isTargeted', isTargeted);
  }
})();


 })(window);