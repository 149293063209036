(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/targeting/assets/javascripts/targeting-analytics.js') >= 0) return;  svs.modules.push('/components/components/targeting/assets/javascripts/targeting-analytics.js');

var svs = svs || {};

(function(svs, $) {
  'use strict';

  function track() {
    var targeting = svs.components.targeting || {};
    var targets = targeting.targets || undefined;
    var evtFn = svs.components.analytics ?
        svs.components.analytics.trackEvent :
        undefined;

    if (evtFn && targets) {
      var tests = Object.keys(targets);
      var i = tests.length;

      while (i) {
        i--;
        var evt = {
          category: 'A/B Test',
          action: tests[i],
          opt_label: svs.components.targeting.getTarget(tests[i])
        };
        evtFn(evt);
      }
    }
  }

  $(document).ready(function() {
    track();
  });

  svs.components.targeting.track = track;
})(svs, $);


 })(window);